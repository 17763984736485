import React, { useState } from "react";
import { useNavigate } from "react-router";

const Menu = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const clickHandler = (e) => {
    setShow(!show);
  };

  return (
    <>
      <div className="menu-bars" onClick={clickHandler}>
        <div className="b1"></div>
        <div className="b2"></div>
        <div className="b3"></div>
      </div>

      {show ? (
        <div className="menu-card">
          <div className="child">
            <p
              onClick={() => {
                setShow(!show);
                navigate("/");
              }}
            >
              Home
            </p>
            <p
              onClick={() => {
                setShow(!show);
                navigate("/about");
              }}
            >
              About
            </p>

            <p
              onClick={() => {
                setShow(!show);
                navigate("/expertise");
              }}
            >
              Expertise
            </p>

            <p
              onClick={() => {
                setShow(!show);
                navigate("/projects");
              }}
            >
              Work
            </p>
            <p
              onClick={() => {
                setShow(!show);
                navigate("/contact");
              }}
            >
              Coffee with me
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Menu;
