import React from "react";
import { useNavigate } from "react-router";

const About = () => {
  const navigate = useNavigate();
  return (
    <div className="about-parent">
      <p className="nxt" onClick={() => navigate(`/expertise`)}>
        Next
        <span className="line"></span>
      </p>
      <p className="prev" onClick={() => navigate(`/`)}>
        Prev <span className="line"></span>
      </p>

      <div className="about-child">
        <div className="c1"></div>
        <div className="c2"></div>
        <div className="c3"></div>
        <div className="c4"></div>

        {window.innerWidth > "768px" ? (
          <lord-icon
            className="icon"
            src="https://cdn.lordicon.com/bhcvxmsz.json"
            trigger="loop"
            colors="primary:#ffffff,secondary:#ffffff"
            stroke="55"
            delay="1500"
            style={{
              width: "200px",
              height: "200px",
              position: "absolute",
              zIndex: "3",
              top: "-25px",
              right: "91px",
            }}
          ></lord-icon>
        ) : null}

        <div className="info-div">
          <h1>About me</h1>
          <p className="info">
            I am a self taught developer extraordinaire from India. I love
            creating everything from scratch, from building beautiful user
            interfaces to engaging with the community and sharing my knowledge.
            <br></br>
            <br></br>I love to build new products with a vision to improve them
            over the period of time and offer it to the people to simplify their
            lives.
            <br></br>
            <br></br> Whether I'm saving the day with pixel-perfect designs and
            a heart full of passion, I'm always ready for adventure!
            <br></br>
            <br></br>
            {/* <a
              href="https://drive.google.com/drive/folders/1iRPhdqK0TneQbclhAwtSCIpIylfZOP67?usp=sharing"
              target="_blank"
              rel="noreferrer"
              style={{fontSize: "18px", fontWeight: "600", color: "#fff", textDecoration: "underline"}}
            >
              
            Checkout My Resume
            </a> */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
