import React, {useState} from "react";
import { useNavigate } from "react-router";

const Contact = () => {
  const navigate = useNavigate();
  const [click, setClick] = useState("Connect with me");
  const [contactInfo, setContactInfo] = useState({
    cname: "",
    email: "",
    number: "",
    message: "",
  })
  // const [projName, setProjName] = useState("proj1");


  async function submitHandler() {
    if(!contactInfo.cname || !contactInfo.email || !contactInfo.number || !contactInfo.message) {
      alert("Please enter all the details");
    } else {
      setClick("Loading...");
      const response = await fetch(
        "https://react-frosted.herokuapp.com/contact",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ...contactInfo }),
        }
      );

      const data = await response.json();
       if(data) {
        setClick("Success");
       }
      console.log(data);

      if (response.status !== 200) {
        alert("failure");
      } else {
        setContactInfo({
          cname: "",
          email: "",
          number: "",
          message: "",
        });
        console.log("success");
      }
    }
  }

  return (
    <div className="contacts-parent">
      <p className="nxt" onClick={() => navigate("/")}>
        Next
        <span className="line"></span>
      </p>
      <p className="prev" onClick={() => navigate("/projects")}>
        Prev
        <span className="line"></span>
      </p>

      <div className="contacts-child">
        <div className="c1"></div>
        <div className="c2"></div>
        <div className="c3"></div>
        <div className="c4"></div>
       
        <div className="info-div">
          <h1>Coffee with me  <lord-icon
           src="https://cdn.lordicon.com/uxtcmjmu.json"
          trigger="loop"
          colors="primary:#ffffff,secondary:#ffffff"
          stroke="70"
          delay="1900"
          style={{
            width: "80px",
            height: "69px",
            // position: "absolute",
            zIndex: "3",
            // top: "42px",
            // right: "127px",
          }}
        ></lord-icon></h1>

          <div className="contacts-grid">
            <form className="form">
              <div className="each d1">
                <label htmlFor="name">Name</label>
                <input type="text" id="name" value={contactInfo.cname} onChange={(e) => setContactInfo({...contactInfo, cname: e.target.value})}/>
              </div>

              <div className="each d2">
                <label htmlFor="email">Email</label>
                <input type="email" id="email"  value={contactInfo.email}onChange={(e) => setContactInfo({...contactInfo, email: e.target.value})}/>
              </div>

              <div className="each d3">
                <label htmlFor="number">Number</label>
                <input type="text" id="number" value={contactInfo.number} onChange={(e) => setContactInfo({...contactInfo, number: e.target.value})}/>
              </div>

              <div className="each d4">
                <label htmlFor="message">Message</label>
                <input type="text" id="message" value={contactInfo.message} onChange={(e) => setContactInfo({...contactInfo, message: e.target.value})} />
              </div>

              <div className="contact-btn btn" onClick={() => submitHandler()}> {click} </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
