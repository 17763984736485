import React from "react";
import { useNavigate } from "react-router";

const Expertise = () => {
  const navigate = useNavigate();

  return (
    <div className="expertise-parent">
      <p className="nxt" onClick={() => navigate("/projects")}>
        Next
        <span className="line"></span>
      </p>
      <p className="prev" onClick={() => navigate("/about")}>
        Prev <span className="line"></span>
      </p>

      <div className="expertise-child">
        <div className="c1"></div>
        <div className="c2"></div>
        <div className="c3"></div>
        <div className="c4"></div>
        
        {window.innerWidth > "768px" ? (
          <lord-icon
            className="icon"
            src="https://cdn.lordicon.com/lupuorrc.json"
            trigger="loop"
            colors="primary:#ffffff,secondary:#ffffff"
            stroke="55"
            delay="1900"
            style={{
              width: "200px",
              height: "200px",
              position: "absolute",
              zIndex: "3",
              top: "-25px",
              right: "91px",
            }}
          ></lord-icon>
        ) : null}

        <div className="info-div">
          <h1>My Expertise</h1>

          <p className="info">
            <div>
              <p> Html5 </p>
              <p> css3 </p>
              <p> scss </p>
              <p> javascript </p>
              <p> jQuery </p>
            </div>

            <div>
              <p> React </p>
              <p> Node </p>
              <p> Express </p>
              <p> MongoDB </p>
              <p> Mysql </p>
              <p> Tailwind </p>
            </div>

            <div>
              <p> Material UI </p>
              <p> Postman </p>
              <p> AWS </p>
              <p> Figma </p>
              <p> GitHub </p>
              <p> Git </p>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Expertise;
