import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

const Home = () => {
  const [display, setDisplay] = useState(true);
  const [time, setTime] = useState(Date.now());
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(Date.now());
      setDisplay((pre) => {
        if (pre === true) {
          return false;
        } else {
          return true;
        }
      });
    }, 3300);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="parent">
      <p className="nxt" onClick={() => navigate("/about")}>
        Next
        <span className="line"></span>
        {/* <span className="circle"></span> */}
      </p>
      <p className="prev">
        Prev <span className="line"></span>
      </p>
      <div className="child">
        <div className="c1"></div>
        <div className="c2"></div>
        <div className="c3"></div>
        <div className="c4"></div>

        {display ? (
          <div className="intro-div">
            <h1 key={time} className="intro">
              Hi
              <lord-icon
                src="https://cdn.lordicon.com/adiugfir.json"
                trigger="loop"
                colors="primary:#ffffff,secondary:#ffffff"
                stroke="55"
                delay="1800"
                style={{
                  width: "100px",
                  height: "100px",
                  marginTop: "-20px",
                }}
              ></lord-icon>{" "}
              This is Chinmay
            </h1>
          </div>
        ) : (
          <div className="intro1-div">
            <h1 key={time + 1} className="intro1">
              I am a fullstack developer
            </h1>
          </div>
        )}

        <div className="social-container">
          {/* <a
            href="https://www.instagram.com/chinmay4o/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fa fa-instagram" aria-hidden="true"></i>
          </a> */}

          <a
            href="https://www.linkedin.com/in/chinmay4o/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fa fa-linkedin" aria-hidden="true"></i>
          </a>

          <a
            href="https://www.twitter.com/chinmay4o/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fa fa-twitter" aria-hidden="true"></i>
          </a>

          <a
            href="https://www.github.com/chinmay4o/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fa fa-github" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
