import "./App.css";
import "./scss/style.css";
import "./scss/mobile.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Expertise from "./pages/Expertise";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import Menu from "./pages/Menu";
import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
      // Redirect to new URL
      window.location.href = 'https://chinmay.vercel.app/';
    }
  },[]);

  return (
    <>
      <Menu />
      <Routes>
        <Route exact path="/" element={<Home />} />

        <Route path="/about" element={<About />} />

        <Route path="/expertise" element={<Expertise />} />

        <Route path="/projects" element={<Projects />} />

        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
