// import React, { useState, useEffect } from "react";
import React, { useState } from "react";
import { useNavigate } from "react-router";

const Projects = () => {
  const navigate = useNavigate();
  const [projName, setProjName] = useState("proj1");

  const handleProject = () => {
    if (projName === "proj1") {
      setProjName("proj2");
    } else if (projName === "proj2") {
      setProjName("proj3");
    } else if (projName === "proj3") {
      setProjName("proj4");
    } else if (projName === "proj4") {
      setProjName("proj5");
    } else if (projName === "proj5") {
      setProjName("proj6");
    } else if (projName === "proj6") {
      setProjName("proj1");
    }
  };

  return (
    <div className="projects-parent">
      <p className="nxt" onClick={() => navigate("/contact")}>
        Next
        <span className="line"></span>
      </p>
      <p className="prev" onClick={() => navigate("/expertise")}>
        Prev
        <span className="line"></span>
      </p>

      <div className="projects-child">
        <div className="c1"></div>
        <div className="c2"></div>
        <div className="c3"></div>
        <div className="c4"></div>

        {window.innerWidth > "768px" ? (
          <lord-icon
            src="https://cdn.lordicon.com/dxjqoygy.json"
            trigger="loop"
            colors="primary:#ffffff,secondary:#ffffff"
            stroke="70"
            delay="1900"
            style={{
              width: "130px",
              height: "99px",
              position: "absolute",
              zIndex: "3",
              top: "38px",
              right: "257px",
            }}
          ></lord-icon>
        ) : null}

        <div className="info-div">
          <h1>My Projects</h1>
          <i
            class="fa fa-angle-right right"
            aria-hidden="true"
            onClick={handleProject}
          ></i>
          <i
            class="fa fa-angle-left left"
            aria-hidden="true"
            onClick={handleProject}
          ></i>

          <div className="projects-grid">
            {projName === "proj1" ? (
              <>
                <a
                  href="https://events-app-eight-gamma.vercel.app/login"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/images/pro1.png" alt="pro1" key="pro1" />
                </a>
                <a
                  className="visit-link"
                  // href="https://events-app-eight-gamma.vercel.app/login"
                  target="_blank"
                  rel="noreferrer"
                >
                  Live <i class="fa fa-external-link" aria-hidden="true"></i>
                </a>
                <a
                  className="visit-link"
                  href="https://github.com/chinmay4o/events-app.git"
                  target="_blank"
                  rel="noreferrer"
                >
                  Github <i class="fa fa-external-link" aria-hidden="true"></i>
                </a>
              </>
            ) : projName === "proj2" ? (
              <>
                <a
                  href="https://github.com/chinmay4o/ecommerce-client"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/images/pro2.png" alt="pro2" key="proj2" />
                </a>
                <a
                  className="visit-link"
                  href="https://github.com/chinmay4o/ecommerce-client"
                  target="_blank"
                  rel="noreferrer"
                >
                  visit <i class="fa fa-external-link" aria-hidden="true"></i>
                </a>
              </>
            ) : projName === "proj3" ? (
              <>
                <a
                  href="https://expensetracker4o.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/images/pro3.png" alt="pro3" key="proj3" />
                </a>
                <a
                  className="visit-link"
                  href="https://expensetracker4o.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  visit <i class="fa fa-external-link" aria-hidden="true"></i>
                </a>
                <a
                  className="visit-link"
                  href="https://github.com/chinmay4o/Expense-tracker-app.git"
                  target="_blank"
                  rel="noreferrer"
                >
                  Github <i class="fa fa-external-link" aria-hidden="true"></i>
                </a>
              </>
            ) : projName === "proj4" ? (
              <>
                <a
                  href="https://github.com/chinmay4o/mystay-backend-aws.git"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/images/pro4.png" alt="pro4" key="proj4" />
                </a>
                <a
                  className="visit-link"
                  href="https://github.com/chinmay4o/mystay-backend-aws.git"
                  target="_blank"
                  rel="noreferrer"
                >
                  visit <i class="fa fa-external-link" aria-hidden="true"></i>
                </a>
              </>
            ) : projName === "proj5" ? (
              <>
                <a
                  href="https://cashregister4o.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/images/pro5.png" alt="pro5" key="proj5" />
                </a>
                <a
                  className="visit-link"
                  href="https://cashregister4o.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  visit <i class="fa fa-external-link" aria-hidden="true"></i>
                </a>
              </>
            ) : projName === "proj6" ? (
              <>
                <a
                  href="https://designer-svg4o.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/images/pro6.png" alt="pro6" key="proj6" />
                </a>
                <a
                  className="visit-link"
                  href="https://designer-svg4o.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  visit <i class="fa fa-external-link" aria-hidden="true"></i>
                </a>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;

// export function useWindowDimensions() {

//   const hasWindow = typeof window !== 'undefined';

//   function getWindowDimensions() {
//     const width = hasWindow ? window.innerWidth : null;
//     const height = hasWindow ? window.innerHeight : null;
//     return {
//       width,
//       height,
//     };
//   }

//   const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

//   useEffect(() => {
//     if (hasWindow) {
//       function handleResize() {
//         setWindowDimensions(getWindowDimensions());
//       }

//       window.addEventListener('resize', handleResize);
//       return () => window.removeEventListener('resize', handleResize);
//     }
//   }, [hasWindow]);

//   return windowDimensions;
// }
